import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Slide,
    TextField,
    MenuItem,
    Autocomplete
} from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| Merchant ADD DIALOG ||============================== //

const Add = ({ open, handleCloseDialog }) => {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        // player_name: 0,
        // player_mobile: 0,
        // ifsc_code: 35
        return_url: 'https://google.com',
        player_id: '1'
    });

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);

    const validationSchema = yup.object({
        return_url: yup.string().required('Required Field').nullable(),
        player_id: yup.string().required('Required Field').nullable(),
        transaction_amount: yup.number().required('Required Field').nullable(),
        order_id: yup.string().required('Required Field').nullable()
    });

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            setLoading(true);
            console.log('user', user, data);

            axiosServices
                .post('/merchant/manual/create/transaction', data, {
                    headers: {
                        APPID: user?.merchantId
                    }
                })
                .then((res) => {
                    setLoading(false);
                    formik.resetForm();
                    setDetails({
                        return_url: 'https://google.com',
                        player_id: '1'
                    });
                    handleCloseDialog(res.data);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.message || 'Saved Successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while adding details',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                });
        }
    });

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="xs" keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Create Payin Txn</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={1} sx={{ mt: 0.25 }}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="player_id"
                                    name="player_id"
                                    sx={{ mb: 2 }}
                                    label="Player ID"
                                    value={formik.values.player_id || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.player_id)}
                                    helperText={formik.errors.player_id}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="player_name"
                                    sx={{ mb: 2 }}
                                    name="player_name"
                                    label="Player Name"
                                    value={formik.values.player_name || null}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^[a-zA-Z\s]*$/.test(value)) {
                                            // Allow only letters and spaces
                                            formik.handleChange(e); // Let Formik handle the value update
                                        }
                                    }}
                                    error={Boolean(formik.errors.player_name)}
                                    helperText={formik.errors.player_name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="player_mobile"
                                    sx={{ mb: 2 }}
                                    name="player_mobile"
                                    label="Player Mobile"
                                    value={formik.values.player_mobile || null}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        // Remove all non-numeric characters
                                        const numericValue = value.replace(/[^0-9]/g, '');

                                        // Restrict input to a maximum of 10 digits
                                        if (numericValue.length <= 10) {
                                            formik.handleChange(e); // Let Formik handle the value update
                                        }
                                    }}
                                    error={Boolean(formik.errors.player_mobile)}
                                    helperText={formik.errors.player_mobile}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="transaction_amount"
                                    name="transaction_amount"
                                    label="Amount"
                                    sx={{ mb: 2 }}
                                    value={formik.values.transaction_amount || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.transaction_amount)}
                                    helperText={formik.errors.transaction_amount}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="order_id"
                                    name="order_id"
                                    label="Order ID"
                                    sx={{ mb: 2 }}
                                    value={formik.values.order_id || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.order_id)}
                                    helperText={formik.errors.order_id}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="return_url"
                                    name="return_url"
                                    label="Return URL"
                                    sx={{ mb: 2 }}
                                    value={formik.values.return_url || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.return_url)}
                                    helperText={formik.errors.return_url}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({
                                    return_url: 'https://google.com',
                                    player_id: '1'
                                });
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

Add.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default Add;
